import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import iotImg from "../../../assets/Allcoureses/internet of things.png"
import Section3 from './../../../components/innerPage/section3/section3';

function InternetOfThings() {
     //section1Data
  const section1Data = {
    sectImg:iotImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Internet Of Things</span> with the best instructors
      </h1>
    ),
    description:
     "This IoT course covers concepts, protocols, and applications. involving the use of sensors, actuators, and the cloud. Ideal for comprehending and developing networked intelligent devices. Appropriate for all levels of proficiency.",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: "Connect various systems to create a network of things that function according to the users' needs.",
    points : [
      "Basics and fundamentals of electronics and numerous protocols.",
      "Detailed concepts of prototyping and network connected architectures.",
      "Train in PCB, manufacturing, and communication networks with well experienced tutors.",
    ]
  };

  //section 3
  const section3Data = [
    {
      topic: "introduction",
      subTopics: [
       "basics of IOT",
       "devices to share information across globe",
      ],
    },
    {
      topic: "electronics basics",
      subTopics: [
        "basics of subjects",
        "foundations of electronics",
      ],
    },
    {
      topic: "embedded electronics basics",
      subTopics: [
        "basics of embedded electronics",
        "specialized circuits",
        "related hardwares",
      ],
    },
    {
      topic: "wired and wireless protocols",
      subTopics: [
        "LoRaWAN, Zigbee, IEEE802.11",
        "External and internal communication concepts are overviewed."
      ],
    },
  ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default InternetOfThings
