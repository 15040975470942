import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import androidImg from "../../../assets/Allcoureses/Android development.png";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import Section3 from './../../../components/innerPage/section3/section3';

function AppDevelopment() {
          //section1Data
  const section1Data = {
    sectImg : androidImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Android App Development</span> with the best instructors
      </h1>
    ),
    description:
     "Explore app development with our course. Learn programming, UI/UX design, and deployment for Android and iOS. Hands-on projects, experienced instructors, and industry-relevant skills. Start creating today!",
    link: "/internship-program",
  };

   //section2Data
   const section2Data = {
    description: "Create and design software applications based on the incoming demands and use.",
    points : [
      "Put your programming skills in real time applications and development.",
      "Learn to use and work with android studio, broadcast receivers and many more.",
      "Learn to apply specific programming languages into functioning apps.",
    ]
  };
   //section 3
   const section3Data = [
    {
      topic: "introduction to android development",
      subTopics: [
      "basic architecture of android platform",
      "tools used by android application developer"
      ],
    },
    {
      topic: "android studio setup environment",
      subTopics: [
        "set the environment variables",
        "identify fundamentals of this program",
      ],
    },
    {
      topic: "android app components",
      subTopics: [
      "learn about all the components",
      "develop first hello world application",
      "emulator",
      ],
    },
    {
      topic: "android intents and activities",
      subTopics: [
        "android services and broadcast receivers",
        "android content providers and fragments",
        "android layouts and controls",
        "android styles and themes, event and handlings"
      ],
    },
  ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default AppDevelopment
