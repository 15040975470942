import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import embeddedImg from "../../../assets/Allcoureses/embedded.png"
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import Section3 from './../../../components/innerPage/section3/section3';

function EmbeddedSystem() {
    //section1Data
  const section1Data = {
    sectImg : embeddedImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Embedded System</span> with the best instructors
      </h1>
    ),
    description:
     "Learn about real-time operating systems, microcontrollers, programming, interfaces, and the integration of the Internet of Things in our course. Learn techniques for developing effective and distinct embedded solutions. Appropriate for all levels of proficiency.",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: "Apply your configuration and structural software skills to an advanced level on embedded systems.",
    points : [
     "Various microprocessors and learn to work on them.",
     "Develop various other microprocessor designs embedded on a single chip.",
     "Learn ALP operations, timers and counters, communication protocols and memory management.",
    ]
  };

  //section 3
  const section3Data = [
    {
      topic: "embedded system",
      subTopics: [
     "basics of embedded systems",
     "application",
     "overview of the course"
      ],
    },
    {
      topic: "8051 architectures",
      subTopics: [
       "basics of 8051",
       "intel designed microcontrollers",
       "instructions and significant advantages",
       "advanced learning of 8051",
      ],
    },
    {
      topic: "Memory management",
      subTopics: [
             "Memory management with SFRs",
             "Detailed learning of SFRs",
      ],
    },
    {
      topic: "ALP operations",
      subTopics: [
        "construction of assembly level circuit",
        "basic assembly level language."
      ],
    },
  ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default EmbeddedSystem
