import { useEffect } from 'react';
import "./App.css";
import Home from "./pages/home/home";
import { useRoutes,useLocation } from "react-router-dom";
import MachineLearning from "./pages/courses/machineLearning/machineLearning";
import DataScience from "./pages/courses/data science/dataScience";
import AI from "./pages/courses/artificialInteligence/AI";
import WebDevelopment from './pages/courses/web development/WebDevelopment';
import CyberSecurity from './pages/courses/cyber security/CyberSecurity';
import AppDevelopment from './pages/courses/AppDevelopment/AppDevelopment';
import HybridElectricVehicles from './pages/courses/Hybrid electric vehicles/HybridElectricVehicles';
import InternetOfThings from './pages/courses/Internet of things/InternetOfThings';
import Robotics from './pages/courses/Robotics/Robotics';
import AutoCad from './pages/courses/autoCAD/AutoCad';
import IcEngine from './pages/courses/IC Engine/IcEngine';
import DigitalMarketing from './pages/courses/Digital marketing/DigitalMarketing';
import ConstructionPlanning from './pages/courses/Construction planning/ConstructionPlanning';
import Finance from './pages/courses/Finance/Finance';
import StockMarket from './pages/courses/Stock market/StockMarket';
import NanoTechnologies from './pages/courses/NanoTechnologies/NanoTechnologies';
import Footer from "./components/footer/footer";
import Navbar from './components/navbar/navbar.js';
import ComingSoon from './pages/comingSoon/comingSoon';
import Contact from "./pages/contactUs/contactUs";
import PopUpModel from './components/popupModal/PopUpModal';
import EmbeddedSystem from './pages/courses/Embedded systems/EmbeddedSystem';
import InternshipLanding from "./pages/InternshipLanding/internshipLanding";
import Privacy from './pages/privacy/privacy';
import Termsandconditions from './pages/TermsAndConditions/termsandconditions';
import Aboutus from './pages/AboutUs/aboutus';
import Career from './pages/Career/career';
import Blog from './pages/Blog/Blog';
import BlogPost1 from './pages/Blog/BlogPost1';
import BlogPost2 from './pages/Blog/BlogPost2';
import BlogPost3 from './pages/Blog/BlogPost3';
import Hiring from './pages/Hiring/hiring';
import GeneticEngineering  from './pages/courses/GeneticEngineering/GeneticEngineering';
import CloudComputing from "./pages/courses/cloudComputing/cloudComputing";
import CarDesign from './pages/courses/CarDesign/CarDesign';
import Humanresources from './pages/courses/Human resources/Humanresources';
import Marketing from "./pages/courses/Marketing/Marketing";
import ARVR from "./pages/courses/ARVR/Arvr";


//departments
import ComputerScience from './pages/departments/computerScience/computerScience';
import ElectronicsAndCommunication from './pages/departments/ElectronicAndCommunnication/electronicsAndCommunication';
import Biotechnology from './pages/departments/Biotechnology/biotechnology'
import CivilAndMechanical from './pages/departments/civilAndMechanical/civilAndMechanical'
import Management from './pages/departments/management/Management' 
import VLSIDesign from './pages/courses/VLSIDesign/VLSIDesign';
import BusinessAnalytics from './pages/courses/BusinessAnalytics/BusinessAnalytics';
import BioInformatics from './pages/courses/BioInformatics/BioInformatics';


function App() {
  const location = useLocation()
  const routes = [
    { path: "/", element: <Home /> },
    { path: "/machineLearning", element: <MachineLearning /> },
    { path: "/businessanalytics", element: <BusinessAnalytics /> },
    { path:'/computer-science', element:<ComputerScience/>},
    { path:'/internship-program',element:<InternshipLanding/>},
    { path: "/coming-soon", element: <ComingSoon /> },
    { path: "/contact", element: <Contact/> },
    { path:"/privacy", element: <Privacy />},
    { path:"/Termsandconditions", element: <Termsandconditions />},
    {path: "/dataScience", element: <DataScience/>},
    {path: "/AI", element: <AI/>},
    {path: "/WebDevelopment", element: <WebDevelopment/>},
    {path:"/CyberSecurity", element:<CyberSecurity/>},
    {path:"/AppDevelopment", element:<AppDevelopment/>},
    {path:"/EmbeddedSystem", element:<EmbeddedSystem/>},
    {path:"/VLSIDesign", element:<VLSIDesign/>},
    {path:"/BioInformatics", element:<BioInformatics/>},
    {path:"/HybridElectricVehicles", element:<HybridElectricVehicles/>},
    {path:"/InternetOfThings", element:<InternetOfThings/>},
    {path:"/Robotics", element:<Robotics/>},
    {path:"/AutoCad", element:<AutoCad/>},
    {path:"/IcEngine", element:<IcEngine/>},
    {path:"/ConstructionPlanning", element:<ConstructionPlanning/>},
    {path:"/DigitalMarketing", element:<DigitalMarketing/>},
    {path:"/Finance", element:<Finance/>},
    {path:"/StockMarket", element:<StockMarket/>},
    {path:"/NanoTechnologies", element:<NanoTechnologies/>},
    {path:"/PopUpModel", element:<PopUpModel/>},
    {path:'/electronicsAndCommunication', element:<ElectronicsAndCommunication/>},
    {path:'/Biotechnology', element:<Biotechnology/>},
    {path:'/civilAndMechanical', element:<CivilAndMechanical/>},
    {path:'/Management', element:<Management/>},
    {path:'/Aboutus', element:<Aboutus/>},
    {path: "/Hiring", element:<Hiring/ > },
    
    {path:"/Career", element:<Career/>},
    {path:"/Blog", element:<Blog/>},
    {path:"/BlogPost1",element:<BlogPost1/>},
    {path:"/BlogPost2",element:<BlogPost2/>},
    {path:"/BlogPost3",element:<BlogPost3/>},
    {path:'/GeneticEngineering',element:<GeneticEngineering/>},
    {path:'/Cloud-computing',element:<CloudComputing/>},
    {path:'/CarDesign',element:<CarDesign/>},
    {path:'/Humanresources',element:<Humanresources/>},
    {path:'/Marketing',element:<Marketing/>},
    {path:"/Arvr",element:<ARVR/>}
  ];
  const Wrapper = () => {
    return useRoutes(routes);
  };

  useEffect(()=>{
  window.scrollTo(0,0);
  },[location])
  return (
    <>
      <Navbar/>
      <Wrapper />
      <Footer />
    </>
  );
}

export default App;
